import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TopMenu from './interface/TopMenu';
import GameTabView from './interface/GameTabView';
import PhaserCanvas from './phaser/PhaserCanvas';
import { loadPyodideWithThonking } from './primus/thonking/load.js';
import { storedTank, ThonkTank } from './primus/thonking/thonktank';
import Music from './phaser/audio/Music';
import { DEFAULT_CONTROLS, getAresHost } from './constants';
import GameScene from './phaser/scenes/GameScene.js';
import { loadingGlobals } from './phaser/scenes/Loading.js';

function Game({user, playerID, setClearGame, gameHooks, setGameHooks}) {
	const [game, setGame] = useState(null);
	const [tickCount, setTickCount] = useState(0);

	const [targetMob, setTargetMob] = useState(null);

	const [controls, setControls] = useState(DEFAULT_CONTROLS);
	const [musicVolume, setMusicVolume] = useState(0.3);
	const [fxVolume, setFxVolume] = useState(1);

	const [music] = useState(new Music(getAresHost("town"), musicVolume));

	loadingGlobals.game = game;
	loadingGlobals.gameHooks = gameHooks;
	loadingGlobals.setGameHooks = setGameHooks;
	loadingGlobals.controls = controls;

	useEffect(()=>{
		setGameHooks({
			user: user,
			playerID: playerID,
			setTargetMob: setTargetMob,
			setTickCount: setTickCount,
			controls: controls,
			area: "town",
		});
	}, [controls, user, playerID, setTargetMob, setTickCount, setGameHooks]);

	useEffect(()=>{
		if (gameHooks === undefined && setGameHooks === undefined) {
			return;
		}
		game?.scene?.keys?.GameScene?.trackHooks(gameHooks, setGameHooks);
	}, [game, gameHooks, setGameHooks]);

	const volumes = {
		musicVolume: musicVolume,
		setMusicVolume: setMusicVolume,
		fxVolume: fxVolume,
		setFxVolume: setFxVolume,
	}


	useEffect(() => {
		music.setVolume(musicVolume);
	}, [musicVolume, music]);

	useEffect(() => {
		game?.scene?.keys?.GameScene?.soundEffects?.setVolume(fxVolume);
	}, [fxVolume, game]);

	const [thonkTank, setThonkTank] = useState(null);

	useEffect(() => {
		loadPyodideWithThonking().then((p) => {
			storedTank.tank = new ThonkTank(p);
			setThonkTank(storedTank.tank);
		});
	}, []);

	useEffect(()=>{
		loadingGlobals.thonkTank = thonkTank;
	}, [thonkTank]);

	useEffect(() => {
		if (game === undefined) {
			return;
		}
		// double function is the only way to put a function in a state hook.
		setClearGame(()=>()=>{
			const gs = game.scene.keys.GameScene;
			const gameHooks = gs.gameHooks;
			const setGameHooks = gs.setGameHooks;
			if (gs.ws !== undefined) {
				gs.ws.onclose = ()=>{};
				gs.ws.close();
			}
			gs.scene.remove();

			const nextScene = game.scene.add("GameScene", GameScene, false);
			nextScene.trackHooks(gameHooks, setGameHooks);
		})
	}, [game, setClearGame]);

	useEffect(() => {
		if (game === null || game.scene.keys.GameScene === undefined) {
			return;
		}
		game.scene.keys.GameScene.setControls(controls);
	}, [controls, game])

	useEffect(() => {
		if (game === null || game.scene.keys.GameScene === undefined) {
			return;
		}
		//game.scene.keys.GameScene.playTheGame(user, playerID, "town");
	}, [playerID, game])

	return (
		<div>
			<TopMenu
				game={game}
				controls={controls}
				setControls={setControls}
				volumes={volumes}
				playerID={playerID} />
			<Container className="playable" fluid>
				<Row>
					<Col xs={5} id="sidebar-wrapper">
						<GameTabView className="gametab" tickCount={tickCount} thonkTank={thonkTank} targetMob={targetMob} game={game} />
					</Col>
					<Col xs={10} id="page-content-wrapper">
						<PhaserCanvas 
							setGame={setGame}
							targetMob={targetMob}
							setTargetMob={setTargetMob}
							setTickCount={setTickCount}
							user={user}
							playerID={playerID} />
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Game;
