import Phaser from 'phaser';

var loadingGlobals = {};
export { loadingGlobals };

export default class LoadingScene extends Phaser.Scene {
	constructor() {
		super('Loading');
	}

	create() {
		this.add.text(100, 100, "loading...");
		
		// Check for thonkTankJar.thonkTank every second
		this.time.addEvent({
			delay: 1000,
			callback: this.checkThonkTank,
			callbackScope: this,
			loop: true
		});
	}

	checkThonkTank() {
		console.log(loadingGlobals);
		if (loadingGlobals.thonkTank === undefined) {
			return;
		}
		// Stop checking once thonkTank exists
		this.time.removeAllEvents();
		const gs = loadingGlobals.game?.scene?.keys?.GameScene;
		gs?.trackHooks(loadingGlobals.gameHooks, loadingGlobals.setGameHooks);
		gs?.setControls(loadingGlobals.controls);
		gs?.scene?.stop();
		this.scene.launch('GameScene');
		this.scene.stop();
	}
}
